import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Typed from 'typed.js';

const Wrap = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Box = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #fff;
  margin-top: 5vh;
  padding: 70px;
  align-items: center;

  img {
    width: 100%;
    height: auto;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: 300px;
      height: 150px;
    }

    @media (max-width: 1300px) , screen and (orientation: portrait)  {
      width: 50%;
    }
  }

  h2 {
    font-weight: 300;
    font-size: 1.8em;
    line-height: 1.8em;
    min-height: 110px;
  }

  @media (max-width: 1300px) , screen and (max-height: 700px)  {
    font-size: 0.8em;
  }
`;

export default () => {
  const text = useRef(null);
  const { t, i18n } = useTranslation('intro');

  useEffect(() => {
    // eslint-disable-next-line no-new
    const typed = new Typed(text.current, {
      stringsElement: '#text-content',
      typeSpeed: 30,
      startDelay: 300,
      showCursor: false,
    });

    return () => {
      typed.destroy();
    };
  }, [i18n.language]);

  return (
    <Wrap>
      <Box>
        <img alt="logo" src={require('@assets/img/logo-big.png').default} />
        <h2 id="text-content">
          <span>
            {t('header')}<br />
            {t('description')}
          </span>
        </h2>
        {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
        <h2 ref={text} />
      </Box>
    </Wrap>
  );
};
