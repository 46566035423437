import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';

import HexGrid from '@components/HexGrid';

import { palette } from '@theme';

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  .hex-view {
    min-width: 800px;
    max-width: 1800px;
    margin: 0 100px;
  }

  @media (max-width: 1300px) , screen and (max-height: 700px)  {
    .hex-view {
      margin: 0 50px;
    }
  }
`;

let timeouts = [];
const clearTimeouts = () => {
  timeouts.forEach(t => clearTimeout(t));
  timeouts = [];
};

export default ({ active }) => {
  const { t } = useTranslation('useCases');
  const getDefaultGridItems = () => ([
    [{ // 1-1
      hexStyle: {
        backgroundImage: require('@assets/img/reactor-2.jpg').default,
        opacity: 0,
        color: palette.primary.main,
      },
      triangles: ['left'],
    }, { // 1-2
      hexStyle: {
        color: '#fff',
        backgroundColor: palette.green,
        opacity: 0,
      },
      triangles: ['left'],
      shortText: <h3>{ t('methane.shortText') }</h3>,
      body: (
        <p>
          <b>{ t('methane.body.em') }</b><br />
          { t('methane.body.norm0') }<br />
          <b>{ t('methane.body.em0') }</b>
        </p>
      ),
    }, { // 1-3
      hexStyle: {
        color: '#fff',
        backgroundColor: palette.primary.light,
        opacity: 0,
      },
      iconStyle: { width: '30%', bottom: '-20px', right: '5px' },
      triangles: ['bottomLeft'],
      clickable: false,
      heading: <h3>{ t('noChems.heading') }</h3>,
      shortText: <h3>{ t('noChems.shortText') }</h3>,
      icon: require('@assets/img/leaf-icon.png').default,
    }, { // 1-4
      hexStyle: {
        color: '#fff',
        backgroundColor: palette.primary.main,
        opacity: 0,
      },
      triangles: ['bottomLeft'],
      clickable: false,
      iconStyle: { width: '30%', bottom: '-16px', right: '5px' },
      heading: <h3>{ t('gasProcessing.heading') }</h3>,
      shortText: <h3>{ t('gasProcessing.shortText') }</h3>,
      icon: require('@assets/img/fire-icon.png').default,
    }],
    [{ // 2-1
      hexStyle: {
        backgroundImage: require('@assets/img/mobile_plant_square2.jpg').default,
        opacity: 0,
        color: palette.primary.main,
      },
      triangles: ['left'],
    }, { // 2-2
      hexStyle: {
        color: '#fff',
        backgroundColor: palette.green,
        opacity: 0,
      },
      triangles: ['left'],
      shortText: <h3>{ t('resFrak.shortText') }</h3>,
      body: (
        <p>
          <b>{ t('resFrak.body.em') }</b><br />
          { t('resFrak.body.norm0') }<br />
          <b>{ t('resFrak.body.em0') }</b>
        </p>
      ),
    }, { // 2-3
      hexStyle: {
        color: '#fff',
        backgroundColor: palette.green,
        opacity: 0,
      },
      triangles: ['bottomLeft', 'left', 'topLeft'],
      clickable: false,
      heading: <h3>{ t('unique.heading') }</h3>,
      shortText: <h3>{ t('unique.shortText') }</h3>,
    }, { // 2-4
      hexStyle: {
        color: '#fff',
        backgroundColor: palette.primary.light,
        opacity: 0,
      },
      triangles: ['left'],
      heading: <h3>{ t('range.heading') }</h3>,
      shortText: <h3>{ t('range.shortText') }</h3>,
      body: (
        <p>
          { t('range.body.norm') } <b key="0">{ t('range.body.em') }</b> { t('range.body.norm0') } <b key="1">{ t('range.body.em0') }</b>
        </p>
      ),
    }, { // 2-5
      hexStyle: {
        color: '#fff',
        backgroundColor: palette.primary.main,
        opacity: 0,
      },
      clickable: false,
      triangles: ['left'],
      iconStyle: { width: '80%', right: '5px' },
      heading: <h3>{ t('municipalWW.heading') }</h3>,
      shortText: <h3>{ t('municipalWW.shortText') }</h3>,
      icon: require('@assets/img/house-water-icon.png').default,
    }],
    [{ // 3-1
      hexStyle: {
        backgroundImage: require('@assets/img/onsite_treatment_square2.jpg').default,
        opacity: 0,
        color: palette.primary.main,
      },
      triangles: ['left'],
    }, { // 3-2
      hexStyle: {
        color: '#fff',
        backgroundColor: palette.green,
        opacity: 0,
      },
      triangles: ['left'],
      shortText: <h3>{ t('bioFrak.shortText') }</h3>,
      body: (
        <p>
          <b>{ t('bioFrak.body.em') }</b><br />
          { t('bioFrak.body.norm0') }<br />
          <b>{ t('bioFrak.body.em0') }</b>
        </p>
      ),
    }, { // 3-3
      hexStyle: {
        color: '#fff',
        backgroundColor: palette.primary.light,
        opacity: 0,
      },
      triangles: ['topLeft'],
      clickable: false,
      iconStyle: { width: '30%', bottom: '-20px', right: '10px' },
      heading: <h3>{ t('efficient.heading') }</h3>,
      shortText: <h3>{ t('efficient.shortText') }</h3>,
      icon: require('@assets/img/rocket-icon.png').default,
    }, { // 3-4
      hexStyle: {
        color: '#fff',
        backgroundColor: palette.primary.main,
        opacity: 0,
      },
      iconStyle: { width: '80%', right: '5px' },
      triangles: ['topLeft'],
      clickable: false,
      heading: <h3>{ t('industrialWW.heading') }</h3>,
      shortText: <h3>{ t('industrialWW.shortText') }</h3>,
      icon: require('@assets/img/factory-water-icon.png').default,
    }],
  ]);

  const [gridItems, setGridItems] = useState(getDefaultGridItems);

  useEffect(() => {
    setGridItems(getDefaultGridItems);
    clearTimeouts();
    if (!active) return;

    timeouts.push(setTimeout(() => {
      gridItems.forEach((row, rowIndex) => {
        row.forEach((hex, hexIndex) => {
          timeouts.push(setTimeout(() => {
            setGridItems(grid => {
              const gridCopy = cloneDeep(grid);
              const copy = cloneDeep(hex);
              copy.hexStyle.opacity = 1;
              gridCopy[rowIndex][hexIndex] = copy;
              return gridCopy;
            });
          }, 300 * ((rowIndex !== 1) + hexIndex)));
        });
      });
    }, 300));
  }, [active]);

  return (
    <Wrap>
      <HexGrid grid={gridItems} className="hex-view" />
    </Wrap>
  );
};
