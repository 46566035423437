import React, { useState, useEffect } from 'react';
import Fade from '@material-ui/core/Fade';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const Wrap = styled.div`
  position: absolute;
  z-index: 999;
  bottom: 20px;
  left: 20px;
  width: 110px;
  padding: 5px;

  @media (orientation: landscape) and (max-width: 700px) , (orientation: portrait) and (max-height: 700px)  {
    display: none;
  }

  img {
    width: 100%;
    height: auto;
  }
  p {
    margin: 0;
    padding-left: 5px;
    background-color: white;
  }
`;

export default ({ swiper }) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!swiper) return;
    if (swiper.activeIndex > 0) setVisible(true);
    swiper.on('slideChangeTransitionStart', () => swiper.activeIndex === 0 && setVisible(false));
    swiper.on('slideChangeTransitionEnd', () => swiper.activeIndex > 0 && setVisible(true));
  }, [swiper]);

  return (
    <Wrap>
      <Fade timeout={600} in={visible}>
        <p>
          {t('news')}
          <a href="https://www.linkedin.com/company/four-e" target="_blank" rel="noreferrer">
            <img alt="LinkedInLogo" src={require('@assets/img/LI-Logo.svg.original.svg').default} />
          </a>
        </p>
      </Fade>
    </Wrap>
  );
};
