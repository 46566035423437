import React from 'react';
import { useTranslation } from 'react-i18next';


export default () => {
  const { t } = useTranslation('contact');
  return (
    <>
      <h1>{ t('imprint') }</h1>
      <div>Four-E Systems GmbH</div>
      <div>Kasseler Str. 47</div>
      <div>34346 Hann. Münden</div>
      <div>{ t('managingDirectors') }: Marcus Looft & Harald R. Klein</div>
      <div>{ t('registration') } HRB 258442</div>
    </>
  );
};
